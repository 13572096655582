<template>
  <div class="wrapper">
    <div class="bot" @click="toHome"></div>
    <img src="https://static.yihu365.cn/img/h5Img/crazySports_bg2.png" alt="">
    <div class="box">
      <div class="list_all"  v-for="(item,index) in detail.target" :key="index">
        <div class="tit"><span>·</span>{{item.typeName}}</div>
        <div class="list_box" :class="'bg'+index">
          <div v-for="(it,index) in item.value.slice(0,3)"
               @click="toGoodsDetail('1',it.roleCode,it.scdSvCode,it.firSvCode,it.serviceType)"
               :key="index"
          ><span>{{it.serviceName}}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed,reactive, onBeforeMount} from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import homeApi from '@axios/home'
import { channel } from '@/config/channel.js';
export default {
  name: "homeCrazySports2",
  setup(){
    let router = useRouter();
    const store = useStore();
    const route = useRoute();

    const detail =reactive([]);
    const getServicesType = () => {
      homeApi.getHotServicesForFKTY().then((res) => {
        console.log(res);
        detail.target = res.data;
        console.log(detail.target)
      })
    }
    getServicesType();

    const toHome = () => {
      window.location.href = "https://mi.yihu365.cn/home?jumpMark=80000001113"
    }
    //跳转
    const toGoodsDetail = (id,roleCode,scdSvCode,firSvCode,serviceType) => {
      if(!id){
        router.push({
          name : 'more',
        })
        return
      }
      if( serviceType == 1){
        router.push({
          name : 'bloodList',
          query : {
            id : id,
            rolecode : roleCode,
            scdcvcode : scdSvCode,
            firsvcode : firSvCode
          }
        })
        return;
      }

      if(serviceType == 'jiyin'){

        router.push({
          name: 'genesDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            serviceType: serviceType
          }
        })
        return;
      }

      if(serviceType == '052' || serviceType == '2' ){
        router.push({
          name: 'newestDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            serviceType: serviceType
          }
        })
        return;
      }

      if(roleCode == '019'){
        router.push({
          name: 'serviceDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode
          }
        })
      }
      if(roleCode == '099'){//肥胖基因那一类
        router.push({
          name: 'goodsDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            examCode : '001'
          }
        })
      }
      else{
        router.push({
          name: 'goodsDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode
          }
        })
      }
    }

    return {
      detail,
      toHome,
      getServicesType,
      toGoodsDetail
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.wrapper {
  position: relative;
  padding-bottom: 100px;
  .bot{
    position: absolute;
    left: 100px; bottom:150px;
    width: 600px;
    height:50px;
    //border:1px solid red;
    z-index: 10;
  }
  img{

    width: 100%;
  }
  .box {
    width: 85%;
    min-height:400px;
    //border:1px solid red;
    position: absolute;
    top:910px;
    left:60px;
    .list_all{
      margin-bottom: 20px;
      .tit {
        font-size: 34px;
        font-weight: bold;
        span{
          font-size: 50px;
          margin-right: 10px;
        }
      }
      .bg0{

        div{
          display: flex;
          align-items: center;
          justify-content: center;

          padding:30px 15px;
          background: url("https://static.yihu365.cn/img/h5Img/csport_icon3.png")  no-repeat 0 0px;
        }
      }
      .bg1{

        div{
          display: flex;
          align-items: center;
          justify-content: center;
          padding:30px 26px;
          background: url("https://static.yihu365.cn/img/h5Img/csport_icon2.png")  no-repeat 0 0px;
        }
      }
      .bg2{
        div{
          display: flex;
          align-items: center;
          justify-content: center;
          padding:30px 28px;
          background: url("https://static.yihu365.cn/img/h5Img/csport_icon1.png")  no-repeat 0 0px;
        }
      }
      .list_box{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding:0 20px;
        div{
          box-sizing: border-box;
          background-size: 100% 100%;
          width:190px;
          height:160px;
          //border:1px solid red;
          text-align: center;
          font-size: 30px;
          color:white;
          font-weight: bold;
        }
      }

    }
  }
}
</style>
